export default function(password) {
  return {
    min_8_charaters: {
      content: "minimum 8 characters",
      validator: () => {
        return password.length >= 8;
      }
    },
    has_a_capital_letter: {
      content: "atleast a capital",
      validator: () => {
        const re = /([A-Z]{1})+/;
        return re.test(password);
      }
    },
    has_a_small_letter: {
      content: "atleast a small",
      validator: () => {
        const re = /([a-z]{1})+/;
        return re.test(password);
      }
    },
    has_a_number: {
      content: "atleast a number",
      validator: () => {
        const re = /([0-9]{1})+/;
        return re.test(password);
      }
    },
    has_a_special_characters: {
      content: "atleast a special character",
      validator: () => {
        const re = /[^A-Za-z0-9\s]/;
        return re.test(password);
      }
    }
  };
}
